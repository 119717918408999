<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Rider</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                        <button class="btn btn-secondary float-right mr-2">
                            <i class="fas fa-cycle"></i> <a href="https://rider.bahokbd.com" target="_blank" style="color:#fff">Rider Panel</a>
                        </button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="id">Id</label>
                                                <input type="text" id="id" v-model="search.id" placeholder="Enter ID" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <input type="text" id="name" v-model="search.name" placeholder="Enter Name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="mobile">Mobile No</label>
                                                <input type="text" id="mobile" v-model="search.mobile" placeholder="Enter Mobile No" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12" v-if="authUser.role_id <= 2">
                                            <div class="form-group">
                                                <label for="hub_id">Hub</label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="nid">Category</label>
                                                <v-select name="category"
                                                    v-model="search.category"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderCategoryList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="nid">Hub Free</label>
                                                <select id="status" v-model="search.hub_free" class="form-control">
                                                    <option :value="0">Select</option>
                                                    <option :value="1">Yes</option>
                                                    <option :value="2">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                                <a v-if="authUser.role_id <= 2" class="btn btn-warning btn-sm" target="_blank" :href="$baseUrl + 'excel-download/rider?id='+search.id+'&name='+search.name+'&mobile='+
                                                    search.mobile +'&hub_id='+search.hub_id+'&category='+ search.category">
                                                    <i class="fa fa-download"></i> Excel
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Total Rider</th>
                                                    <th>Active Rider</th>
                                                    <th>Inactive Rider</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="summary">
                                                <tr class="text-center text-bold">
                                                    <td>{{ summary.total_rider }}</td>
                                                    <td>{{ summary.active_rider }}</td>
                                                    <td>{{ summary.inactive_rider }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                            <div class="card-body mt-20">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm text-center" style="width:2000px">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Address</th>
                                                        <th>Category</th>
                                                        <th>Hub</th>
                                                        <th>Joining Date</th>
                                                        <th>Vehicle</th>
                                                        <th>Residential</th>
                                                        <th>Remuneration Type</th>
                                                        <th>Amount</th>                                                        
                                                        <th>Total Order</th>
                                                        <th>Total Delivered</th>
                                                        <th>Total Cancelled</th>
                                                        <th>Total Pending</th>
                                                        <th>AVG Success Ratio</th>
                                                        <th>Total Service Life</th>
                                                        <th>Bag</th>
                                                        <th>Tshirt</th>
                                                        <th>Status</th>
                                                        <th style="text-align:center;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Address</th>
                                                        <th>Category</th>
                                                        <th>Hub</th>
                                                        <th>Joining Date</th>
                                                        <th>Vehicle</th>
                                                        <th>Residential</th>
                                                        <th>Remuneration Type</th>
                                                        <th>Amount</th>
                                                        <th>Total Order</th>
                                                        <th>Total Delivered</th>
                                                        <th>Total Cancelled</th>
                                                        <th>Total Pending</th>
                                                        <th>AVG Success Ratio</th>
                                                        <th>Total Service Life</th>
                                                        <th>Bag</th>
                                                        <th>Tshirt</th>
                                                        <th>Status</th>
                                                        <th style="text-align:center;">Action</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    <tr v-for="(rider, index) in listData" :key="index">
                                                        <td>{{ rider.id }}</td>
                                                        <td @click="editModal(rider)">{{ rider.name }}</td>
                                                        <td>{{ rider.mobile }}</td>
                                                        <td>{{ rider.address }}</td>
                                                        <td>{{ getCategoryName(rider.category) }}</td>
                                                        <td>{{ rider.hub_name }}</td>
                                                        <td>{{ rider.created_at | dateformat }}</td>
                                                        <td>{{ rider.vehicle }}</td>
                                                        <td>{{ rider.residential == 1 ? 'Yes' : 'No' }}</td>
                                                        <td>{{ rider.remuneration_type == 1 ? 'Monthly Salary' : 'Commission' }}</td>
                                                        <td>{{ rider.amount }}</td>
                                                        <td>{{ rider.total_order }}</td>
                                                        <td>{{ rider.total_delivered }}</td>
                                                        <td>{{ rider.total_cancel }}</td>
                                                        <td>{{ rider.total_pending }}</td>
                                                        <td>{{ rider.avg_success_ratio }}%</td>
                                                        <td>{{ rider.total_service_life }}</td>
                                                        <td>{{ rider.bag }}</td>
                                                        <td>{{ rider.tshirt }}</td>
                                                        <!-- <td><img :src="$image_path+rider.image" alt="Rider Image" style="width:80px;height:50px;"/></td> -->
                                                        <td :class="'rider-status-' + rider.status">{{ rider.status == 0 ? 'Inactive' : 'Active' }}</td>
                                                        <td style="text-align:center;">                                    
                                                            <button class="btn btn-success btn-sm mr-1" @click="showAreaModal(rider)" title="Area"><i class="fa fa-map-marker"></i></button>
                                                            <button v-if="rider.status == 0" class="btn btn-success btn-sm mr-1" @click="statusUpdate(1, rider)" title="Active"><i class="fas fa-check"></i></button>
                                                            <button v-if="rider.status == 1" class="btn btn-danger btn-sm mr-1" @click="statusUpdate(0, rider)" title="Inactive"><i class="fas fa-user-slash"></i></button>
                                                            <button class="btn btn-warning btn-sm mr-1" @click="editModal(rider)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                            <!-- <button class="btn btn-danger btn-sm" @click="deleteModal(index, rider.id)" title="Delete"><i class="fas fa-trash"></i></button> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="areaModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Update Area</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateArea)">
                                    <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required">
                                        <div class="form-group">
                                            <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="area_id"
                                                multiple
                                                v-model="area.area_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= areaList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateArea">Submit</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Add New Rider</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createRider)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>    
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="NID" vid="nid" v-slot="{errors}" rules="required|numeric">
                                                <div class="form-group">
                                                    <label for="nid">NID <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="nid" v-model="form.nid" class="form-control" placeholder="NID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>  
                                            </ValidationProvider>                    
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                             <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="business" v-model="form.address" class="form-control" placeholder="Enter Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                             </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Vehicle" vid="vehicle" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="vehicle">Vehicle <span class="text-danger" title="Required">*</span></label>
                                                    <select id="vehicle" v-model="form.vehicle" class="form-control">
                                                        <option v-for="(vehicle, index) in vehicles" :key="index" :value="vehicle.text">{{ vehicle.text }}</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Residential" vid="residential" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="residential">Residential <span class="text-danger" title="Required">*</span></label>
                                                    <select id="residential" v-model="form.residential" class="form-control">
                                                        <option :value="1">Yes</option>
                                                        <option :value="2">No</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Remuneration Type" vid="remuneration_type" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="remuneration_type">Remuneration Type <span class="text-danger" title="Required">*</span></label>
                                                    <select id="remuneration_type" v-model="form.remuneration_type" class="form-control">
                                                        <option :value="1">Monthlay Salary</option>
                                                        <option :value="2">Commission</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="amount">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="amount" v-model="form.amount" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Bag" vid="bag" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="bag">Bag <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="bag" v-model="form.bag" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Tshirt" vid="tshirt" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="tshirt">Tshirt <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="tshirt" v-model="form.tshirt" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="status">Hub Free</label>
                                                    <select id="status" v-model="form.hub_free" class="form-control">
                                                        <option :value="1">Yes</option>
                                                        <option :value="2">No</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rating" vid="rating" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rating">Rating <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="rating" v-model="form.rating" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>       
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <ValidationProvider name="Category" vid="category" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Category <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="category"
                                                        v-model="form.category"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderCategoryList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>       
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <ValidationProvider name="Comments" vid="comments" v-slot="{errors}" rules="max:200">
                                                <div class="form-group">
                                                    <label for="comments">Comments </label>
                                                    <textarea id="comments" v-model="form.comments" class="form-control" placeholder="Maximum 200 word.."></textarea>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="image">Image</label>
                                                <input type="file" id="image" @change="onImageChange" class="form-control">
                                                <span class="text-danger" v-if="errors.image">{{ errors.address[0] }}</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createRider">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="riderEditModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-lg" role="document"> 
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Rider</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateRider)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                    <input type="hidden" name="id" v-model="form_edit.id" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min_value:11">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>     
                                            </ValidationProvider>                   
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="NID" vid="nid" v-slot="{errors}" rules="required|numeric">
                                                <div class="form-group">
                                                    <label for="nid">NID <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="nid" v-model="form_edit.nid" class="form-control" placeholder="NID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>             
                                            </ValidationProvider>         
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="business" v-model="form_edit.address" class="form-control" placeholder="Enter Address">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Vehicle" vid="vehicle" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="vehicle">Vehicle <span class="text-danger" title="Required">*</span></label>
                                                    <select id="vehicle" v-model="form_edit.vehicle" class="form-control">
                                                        <option v-for="(vehicle, index) in vehicles" :key="index" :value="vehicle.text">{{ vehicle.text }}</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>             
                                            </ValidationProvider>         
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Residential" vid="residential" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="residential">Residential <span class="text-danger" title="Required">*</span></label>
                                                    <select id="residential" v-model="form_edit.residential" class="form-control">
                                                        <option :value="1">Yes</option>
                                                        <option :value="2">No</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Remuneration Type" vid="remuneration_type" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="remuneration_type">Remuneration Type <span class="text-danger" title="Required">*</span></label>
                                                    <select id="remuneration_type" v-model="form_edit.remuneration_type" class="form-control">
                                                        <option :value="1">Monthlay Salary</option>
                                                        <option :value="2">Commission</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Amount" vid="amount" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="amount">Amount <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="amount" v-model="form_edit.amount" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="status">Hub Free</label>
                                                    <select id="status" v-model="form_edit.hub_free" class="form-control">
                                                        <option :value="1">Yes</option>
                                                        <option :value="2">No</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Bag" vid="bag" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="bag">Bag <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="bag" v-model="form_edit.bag" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Tshirt" vid="tshirt" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="tshirt">Tshirt <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="tshirt" v-model="form_edit.tshirt" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Rating" vid="rating" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="rating">Rating <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="rating" v-model="form_edit.rating" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Hub <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="hub_id"
                                                        v-model="form_edit.hub_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.hubList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>       
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                            <ValidationProvider name="Category" vid="category" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="name">Category <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="category"
                                                        v-model="form_edit.category"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= riderCategoryList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>       
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Hub" vid="hub_id" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="status">Status</label>
                                                    <select id="status" v-model="form_edit.status" class="form-control">
                                                        <option :value="1">Active</option>
                                                        <option :value="0">Inactive</option>
                                                    </select>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                            <ValidationProvider name="Comments" vid="comments" v-slot="{errors}" rules="max:200">
                                                <div class="form-group">
                                                    <label for="comments">Comments </label>
                                                    <textarea id="comments" v-model="form_edit.comments" class="form-control" placeholder="Maximum 200 word.."></textarea>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Password" vid="password" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="password">Password <span class="text-danger" title="Required">*</span></label>
                                                    <input type="password" id="bag" v-model="form_edit.new_password" class="form-control" />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>       
                                            </ValidationProvider>               
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="image">Update Image</label>
                                                <input type="file" id="image" @change="onImageChange" class="form-control">
                                                <span class="text-danger" v-if="errors.image">{{ errors.address[0] }}</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateRider">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="riderDeleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyRider">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    import moment from 'moment'
    export default {
        name:'Rider',
        data(){
            return {  
                loader: false, 
                riders:{
                    data: []
                },
                search: {
                    id: '',
                    name: '',
                    mobile: '',
                    nid: '',
                    hub_id: '',
                    hub_free: 0,
                    category: ''
                },
                summary: null,
                form:{
                    name : '',
                    mobile : '',
                    nid : '',
                    address : '',
                    image : '',
                    vehicle : 'Cycle',
                    hub_id : '',
                    category : '',
                    residential: 2,
                    remuneration_type: 2,
                    amount: 0,
                    bag: 0,
                    tshirt: 0,
                    hub_free: 2,
                    rating: 0,
                    comments: ''
                },
                vehicles:[
                    {value:1, text: 'Bike'},
                    {value:2, text: 'Cycle'},
                    {value:3, text: 'Covered Van'}
                ],
                area: {
                    rider_id: 0,
                    area_id: []
                },
                form_edit:'',
                tmpImage:[],
                errors:[],
                areaList:[],
                areaModal:false,
                createModal:false,
                riderEditModal:false,
                riderDeleteModal:false,
                rider_id:'',
                key:'',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created () {
            if (this.$route.query) {
                Object.assign(this.search, this.$route.query)
            } 
            this.loadSummary()
            this.loadData();
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadSummary()
                    this.loadData()
                }
            },
            'search.name' : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            riderCategoryList () {
                return this.$store.state.riderCategoryList
            }
        },
        methods:{
            onImageChange(e){
                this.tmpImage = e.target.files[0];
            },
            searchData () {
                this.loadSummary()         
                this.loadData()         
            },
            async loadSummary () {
                this.loader = true
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search
                const response = await config.getData('/rider/summary', params)
                this.loader = false
                this.summary = response.data          
            },
            async loadData(){     
                this.loader = true 
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })                     
                const response = await config.getData('/rider', params)
                this.loader = false
                if (response.success){
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            getRelationalData (data) { 
                const listData = data.map(item => {
                    const joinDate =  moment(item.created_at, 'YYYY-MM-DD')
                    const serviceLife = moment().diff(joinDate, 'years') + ' Year(s), ' + moment().diff(joinDate, 'months') + ' Month(s), ' + moment().diff(joinDate, 'days') + ' day(s)'                    
                    const hub = this.commonObj.hubList.find(el => el.id == item.hub_id)
                 
                    var totalDelivered  = 0
                    var totalCancel     = 0
                    var totalPending    = 0
                    var avgSuccessRatio = 0

                    const totalOrder = item.orders.filter(el => (el.pr == 2) && (el.ex == 2)).length

                    if (totalOrder > 0) {      

                        item.orders.map(el => {
                            if (el.pr == 2 && el.ex == 2) {
                                totalDelivered += (el.status == 14 || el.status == 15 || el.status == 16 || el.status == 19 || el.status == 20) ? 1 : 0
                                totalCancel   += el.status == 20 ? 1 : 0
                                totalPending   += (el.status == 7 || el.status == 11 || el.status == 12 || el.status == 17 || el.status == 18 || el.status == 30 || el.status == 31) ? 1 : 0
                            }
                        })

                        avgSuccessRatio = (totalDelivered != 0 && totalCancel != 0) ? parseFloat((totalDelivered / (totalDelivered + totalCancel)) * 100).toFixed(2) : '0'
                    }
                    

                    const hubObj = { hub_name : typeof hub !== 'undefined' ? hub.text : '' }
                    const serviceObj = { total_service_life : serviceLife }
                    const orderObj = {
                        total_order: totalOrder,
                        total_delivered: totalDelivered,
                        total_cancel: totalCancel,
                        total_pending: totalPending,
                        avg_success_ratio: avgSuccessRatio
                    }
                    return Object.assign({}, item, hubObj, serviceObj, orderObj)
                })
                return listData
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            showCreateModal(){
                this.createModal = true;
                document.body.classList.add("modal-open");
            },
            async createRider(){ 
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData();
                Object.keys(this.form).map(key => {
                    if (key == 'image') {
                        formData.append(key, this.tmpImage)
                    } else {
                        formData.append(key, this.form[key])
                    }
                })

                const response = await config.postData('/rider/store', formData)
                this.loader = false
                if (response.success) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.createModal = false        
                    this.$toast.success({
                        title: 'Success',
                        message: 'Rider created successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            showAreaModal(rider){
                this.areaModal = true;
                this.area.id = rider.id
                this.areaList = this.commonObj.areaList.filter(el => el.hub_id == rider.hub_id)
                this.area.area_id = JSON.parse(rider.area_id)
                document.body.classList.add("modal-open");
            },
            async updateArea(){ 
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.postData('/rider/update-area', this.area)
                this.loader = false
                if (response.success) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.areaModal = false        
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            cancelModal(){
                this.areaModal = false;
                this.createModal = false;
                this.riderEditModal = false;
                this.riderDeleteModal = false;
                document.body.classList.remove("modal-open");
            },
            editModal(rider){
                this.riderEditModal = true;
                this.form_edit = Object.assign(rider, { new_password: ''});
                document.body.classList.add("modal-open");
            },
            async updateRider(){
                this.loader = true
                this.$store.dispatch('stateReload', true)

                var formData = new FormData()
                Object.keys(this.form_edit).map(key => {
                    if (key == 'image') {
                        formData.append(key, this.tmpImage)
                    } else {
                        formData.append(key, this.form_edit[key])
                    }
                })

                const response = await config.postData('/rider/update', formData)

                this.loader = false
                if (response.success) {
                    this.$store.dispatch('commonObjLoad', true)
                    this.riderEditModal = false         
                    this.$toast.success({
                        title: 'Success',
                        message: 'Rider updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$refs.form.setErrors(response.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            deleteModal(key, rider_id) { 
                this.rider_id = rider_id;
                this.key = key;
                this.riderDeleteModal = true;
            },
            async destroyRider() {
                this.loader = true
                this.$store.dispatch('stateReload', true)
                await config.getData('/rider/destroy/'+ this.rider_id)
                this.loader = false           
                this.riderDeleteModal = false
                this.$toast.success({
                    title: 'Success',
                    message: 'Rider deleted successfully',
                    color: '#218838'
                }) 
                this.$store.dispatch('stateReload', false)
            },
            async statusUpdate(status, rider) { 
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.getData('/rider/status-update/'+rider.id+'/'+status)
                this.loader = false
                if (response.success) {
                    this.$store.dispatch('commonObjLoad', true)   
                    this.$toast.success({
                        title: 'Success',
                        message: 'Status updated successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })   
                }
                this.$store.dispatch('stateReload', false)
            },
            getCategoryName (category) {
                if (category == 1) {
                    return "Delivery"
                } else if (category == 2) {
                    return "Pickup"
                } else if (category == 1) {
                    return "Both"
                }
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>